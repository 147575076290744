import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AssetsService {
    constructor(private httpClient: HttpClient) {}

    getAssetContent(id: string) {
        return this.httpClient.get(
            `${environment.apiUrl}Assets/${id}/content`,
            { responseType: 'blob' }
        );
    }
}
